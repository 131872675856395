import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import { FaFacebookSquare, FaInstagramSquare, FaYoutube } from "react-icons/fa"
import { COLORS } from "../constants"

const SocialsContainer = styled(motion.div)`
  position: fixed;
  z-index: 15;
  right: 1%;
  top: 45%;
`

const Social = styled.a`
  display: block;
  color: ${COLORS.SECONDARY};
  transition: all 0.25s ease-in-out;

  &:hover {
    transform: scale(1.2);
    color: ${COLORS.PRIMARY};
  }
`

const Socials = () => {
  return (
    <SocialsContainer
      initial={{ x: "200px" }}
      animate={{ x: 0, transition: { delay: 0.25, duration: 0.5 } }}
    >
      <Social target="_blank" href="https://www.facebook.com/CompanyNoi/">
        <FaFacebookSquare style={{ height: "2rem", width: "2rem" }} />
      </Social>
      <Social target="_blank" href="https://www.instagram.com/companynoi/">
        <FaInstagramSquare style={{ height: "2rem", width: "2rem" }} />
      </Social>
      <Social
        target="_blank"
        href="https://www.youtube.com/channel/UCqmV83CXvEQhwagDAg0pQlA"
      >
        <FaYoutube style={{ height: "2rem", width: "2rem" }} />
      </Social>
    </SocialsContainer>
  )
}

export default Socials
