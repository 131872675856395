export const COLORS = {
  PRIMARY: "#F0D8C0",
  SECONDARY: "#C5785B",
  ERROR: "#e54b4b",
  SUCCESS: "#cbe56c",
  WHITE: "#EBEAEF",
  BLACK: "#181819",
  GREY: "#242124",
}

export const MEDIA_QUERIES = {
  SMALL: "520px",
  MEDIUM: "980px",
  LARGE: "1200px",
}

export const FONT_FAMILIES = {
  TITLE: "Pistara",
  TEXT: "Avenir-Light",
  BUTTON: "Avenir-Black",
}

export const MENU = [
  {
    title: "Dancers",
    url: "/dancer",
  },
  {
    title: "Workshops",
    url: "/workshop",
  },
  {
    title: "Productions",
    url: "/production",
  },
]

export const MENU2 = [
  {
    title: "About Us",
    url: "/about",
  },
  {
    title: "Support",
    url: "/support",
  },
]

export const SOCIALS = [
  {
    name: "Facebook",
    link: "#",
  },
  {
    name: "Youtube",
    link: "#",
  },
  {
    name: "Instagram",
    link: "#",
  },
]

export const formatDateTime = date => {
  const year = date.substring(0, 4)
  const month = date.substring(5, 7)
  const day = date.substring(8, 10)
  const time = date.substring(11, 16)

  return `${month}/${day}/${year} - ${time}`
}

export const formatDate = date => {
  const year = date.substring(0, 4)
  const month = date.substring(5, 7)
  const day = date.substring(8, 10)

  return `${month}/${day}/${year}`
}
