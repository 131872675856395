import React, { useState } from "react"
import PropTypes from "prop-types"
import { motion } from "framer-motion"
import styled from "styled-components"
import Socials from "./Socials"
import Header from "./header"
import Hamburger from "./Hamburger"
import OverlayMenu from "./OverlayMenu"
import GlobalStyles from "./GlobalStyles"
import "./layout.css"
import "../style.css"
import { COLORS, MEDIA_QUERIES } from "../constants"

const Contact = styled(motion.a)`
  position: fixed;
  top: 45%;
  transform-origin: 0 50%;
  display: block;
  z-index: 10;
  color: ${COLORS.PRIMARY};
  border-bottom: solid 4px ${COLORS.PRIMARY};
  font-size: 1.4rem;

  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
    left: -25px;
    border-bottom: none;
    padding: 0.2rem 0.5rem;
  }
`

const Layout = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const handleOverlayMenu = () => setMenuOpen(!menuOpen)

  return (
    <>
      <GlobalStyles />
      <div style={{ position: "relative" }}>
        <Contact
          initial={{ y: "30%", opacity: 0, rotate: 90 }}
          animate={{
            x: "45%",
            opacity: 1,
            transition: { duration: 0.5, delay: 0.5 },
          }}
          whileHover={{ x: "50px" }}
          href="mailto:valentina@companynoi.com"
        >
          Contact us
        </Contact>
        <Socials />
        <OverlayMenu
          menuOpen={menuOpen}
          handleOverlayMenu={handleOverlayMenu}
        ></OverlayMenu>
        <Hamburger handleOverlayMenu={handleOverlayMenu} />
        <Header />
        <div style={{ paddingTop: "80px" }}>{children}</div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
