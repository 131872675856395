import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { motion } from "framer-motion"
import { CloseButton } from "./styled/Styled"
import LogoNOI from "../images/logo5.png"
import { COLORS, FONT_FAMILIES, MENU, MENU2 } from "../constants"

const Overlay = styled(motion.div)`
  width: 150%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  background: ${COLORS.BLACK};
  left: 0;
  top: 0;
  touch-action: manipulation;

  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${COLORS.BLACK};
    width: 100%;
    height: 100%;
    color: ${COLORS.SECONDARY};
    padding: 1rem 0;
    padding-left: 33%;

    .logo {
      width: 80%;
      text-align: center;
      font-size: 2rem;
      max-width: 400px;
      margin-bottom: 40px;
      color: ${COLORS.BLACK};
    }

    .overlayMenu {
      text-align: center;
      list-style-type: none;
      margin: 0;

      li {
        margin: 0 0 20px 0;
      }

      li.overlayActive {
        color: ${COLORS.SECONDARY};
      }

      a {
        font-family: ${FONT_FAMILIES.TEXT}, Arial, Helvetica, sans-serif;
        font-size: 1.5rem;
        color: ${COLORS.SECONDARY};
        text-transform: uppercase;
        transition: all 0.3s ease;
        text-decoration: none;

        :hover {
          color: ${COLORS.PRIMARY};
        }
      }
    }
  }
`
const OverlayMenu = ({ menuOpen, handleOverlayMenu }) => {
  const overlayVariants = {
    initial: {
      x: " -100%",
    },
    animate: {
      x: "-33%",
      transition: {
        type: "spring",
        stiffness: 100,
        duration: 0.3,
      },
    },
  }
  return (
    <>
      {menuOpen && (
        <Overlay animate="animate" initial="initial" variants={overlayVariants}>
          <div className="inner">
            <Link className="logo" to="/">
              <img src={LogoNOI} alt="logo company noi" />
            </Link>
            <ul className="overlayMenu">
              {MENU.concat(MENU2).map((item, i) => {
                if (item.children) {
                  return item.children.map((child, j) => {
                    return (
                      <li key={j}>
                        <Link
                          onClick={() => handleOverlayMenu(false)}
                          activeClassName="overlayActive"
                          to={child.url}
                        >
                          {child.title}
                        </Link>
                      </li>
                    )
                  })
                } else {
                  return (
                    <li key={i}>
                      <Link
                        onClick={() => handleOverlayMenu(false)}
                        activeClassName="overlayActive"
                        to={item.url}
                      >
                        {item.title}
                      </Link>
                    </li>
                  )
                }
              })}
            </ul>

            <CloseButton
              onClick={handleOverlayMenu}
              role="button"
              tabIndex="0"
              onKeyDown={handleOverlayMenu}
            >
              X
            </CloseButton>
          </div>
        </Overlay>
      )}
    </>
  )
}

export default OverlayMenu
