import { createGlobalStyle } from "styled-components"
import { PistaraOTF, AvenirBlackOTF } from "../fonts/fonts"

export default createGlobalStyle`

  @font-face {
    font-family: "Pistara";
    font-style: normal;
    font-weight: normal;
    src: local("Pistara"), url(${PistaraOTF}) format("opentype");
  }
  @font-face {
    font-family: "Avenir-Black";
    font-style: normal;
    font-weight: normal;
    src: local("Avenir-Black"), url(${AvenirBlackOTF}) format("opentype");
  }

  `
