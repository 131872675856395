import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"

import { MEDIA_QUERIES, COLORS, FONT_FAMILIES } from "../constants"

const NavigationWrapper = styled.nav`
  margin: 15px 0 10px 0; /* just to give some spacing */
  color: #212121;
  font-family: ${FONT_FAMILIES.TEXT}, Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
  color: white;
  letter-spacing: 1px;
  width: 40%;
  text-transform: uppercase;
  align-items: center;
  justify-content: ${({ alignment }) => alignment};
  display: flex;

  .nav-active {
    color: ${COLORS.PRIMARY};
  }

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    display: none;
  }

  @media (min-width: ${MEDIA_QUERIES.LARGE}) {
    font-size: 0.9rem;
  }

  ul {
    margin: 0 1rem;
  }

  ul li {
    display: block;
    margin: 0 10px 0 0;
    float: left;
    height: 30px; /* this should be the same as your #main-nav height */
    position: relative; /* this is needed in order to position sub menus */
    cursor: pointer;

    a {
      padding: 0 5px 0 5px;
      transition: all 0.2s ease;
      color: ${COLORS.SECONDARY};
    }

    span {
      margin: 0 0 0 5px;
    }
  }

  ul li:last-child {
    margin: 0;
  }

  ul li:hover > ul {
    display: block; /* show sub menus when hovering over a parent */
  }

  ul li a:hover {
    color: ${COLORS.PRIMARY};
  }

  ul ul {
    animation: fadeInMenu 0.3s both ease-in;
    display: none; /* hide all sub menus from view */
    position: absolute;
    left: 0;
    margin: 0;
    top: 30px; /* this should be the same height as the top level menu -- height + padding + borders */
    padding: 5px 5px 10px 5px;
    text-transform: none;
    background: ${COLORS.WHITE}; /* Old browsers */
    font-size: 0.7rem;
    -webkit-box-shadow: 0px 6px 23px -10px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 6px 23px -10px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 6px 23px -10px rgba(0, 0, 0, 0.5);
  }

  ul ul li {
    /* this targets all submenu items */
    width: auto; /* set to the width you want your sub menus to be. This needs to match the value we set below */
    min-width: 170px;
  }

  ul ul li a {
    /* target all sub menu item links */
    padding: 5px 10px; /* give our sub menu links a nice button feel */
    text-decoration: none;
    color: ${COLORS.PRIMARY};
    white-space: nowrap;
  }

  @keyframes fadeInMenu {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const Navigation = ({ menu, alignment = "flex-start" }) => (
  <NavigationWrapper alignment={alignment}>
    <ul>
      {menu.map((item, i) => (
        <li key={i}>
          <Link to={item.url} activeClassName="nav-active">
            {item.title}
          </Link>
          {item.children ? (
            <>
              <span>&#8964;</span>
              <ul>
                {item.children.map((child, j) => (
                  <li key={j}>
                    <Link to={child.url} activeClassName="nav-active">
                      {child.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          ) : null}
        </li>
      ))}
    </ul>
  </NavigationWrapper>
)

Navigation.propTypes = {
  menu: PropTypes.array,
}

export default Navigation
