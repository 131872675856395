import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Navigation from "./Navigation"
import { MENU, MENU2, COLORS } from "../constants"
import LogoNOI from "../images/logo5.png"

const HeaderWrapper = styled.header`
  display: flex;
  color: ${COLORS.PRIMARY};
  position: relative;
  justify-content: center;
  background: ${COLORS.BLACK};
  margin-bottom: 1.45rem;
  height: 80px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  padding: 0 2%;

  img {
    max-width: 200px;
    margin: 0;
    padding: 12px 0 0 0;
  }

  .menu {
    display: flex;
    align-items: center;
  }
`

const Logo = styled.div`
  flex-shrink: 0;
  margin: 5px 0 10px 0; /* just to give some spacing */
  width: 20%;
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  text-align: center;
`

const Header = () => {
  return (
    <HeaderWrapper>
      <Navigation menu={MENU} />
      <Logo>
        <Link to="/">
          <img alt="logo company noi" src={LogoNOI} />
        </Link>
      </Logo>
      <Navigation alignment="flex-end" menu={MENU2} />
    </HeaderWrapper>
  )
}

export default Header
